import dynamic from 'next/dynamic'
import React from 'react'

import LazyHydrate from 'react-lazy-hydration'

import InViewport from '~/containers/InViewport'

import useHomePageQuery from '~/hooks/requests/useHomePage'

import HomepageHero from './HomepageHero'

const BrandsSection = dynamic(() => import('~/views/Homepage/BrandsSection'))

const SubscribeSection = dynamic(() =>
  import('~/components/sections/SubscribeSection')
)

const FeaturedListingSection = dynamic(() => import('./FeaturedListingSection'))

const NewsReviewsSection = dynamic(() =>
  import('~/views/Homepage/NewsReviewsSection')
)

const CategoriesSection = dynamic(() =>
  import('~/views/Homepage/CategoriesSection')
)

const RecentlyAddedSection = dynamic(() =>
  import('~/views/Homepage/RecentlyAddedSection')
)

const HomepageView = () => {
  const {
    data: homepageData,
    isFetchedAfterMount,
    isFetching
  } = useHomePageQuery()

  const { featured, mostRecent, topBrands, meta } = homepageData || {}

  const isFeaturedLoading = !!featured?.length && isFetching
  const isMostRecentLoading = !!mostRecent?.length && isFetching

  return (
    <>
      <HomepageHero meta={meta} />

      <LazyHydrate whenVisible>
        <FeaturedListingSection
          data={featured}
          loading={isFeaturedLoading}
          isFetchedAfterMount={isFetchedAfterMount}
        />
      </LazyHydrate>

      <LazyHydrate whenVisible>
        <CategoriesSection />
      </LazyHydrate>

      <InViewport minHeight={1200} rootMargin="500px 0px">
        <RecentlyAddedSection data={mostRecent} loading={isMostRecentLoading} />

        <BrandsSection
          data={topBrands}
          isFetchedAfterMount={isFetchedAfterMount}
        />

        <NewsReviewsSection isPrefetched />

        <SubscribeSection />
      </InViewport>
    </>
  )
}

// const InViewport: FC = ({ children }) => (
//   <InView triggerOnce delay={100} rootMargin="500px 0px">
//     {({ inView, ref }) => (
//       <div ref={ref}>
//         {typeof children === 'function' && children({ inView })}
//       </div>
//     )}
//   </InView>
// )

export default HomepageView
